import { Select } from "antd";
import constants from "../../api/constants";
import React from "react";
import Loading from "../elements/Loading";
import _ from "lodash";

export default function DeveloperFlightsAPIFieldSelect({setFields}) {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);

    const url = constants.API_URL + "/rest/v1/flights/docs";
    fetch(url).then((response) =>
      response.json().then((docs) => {
        // Get the flight fields
        const allFields = _.chain(docs)
          .get("components.schemas.flight.properties")
          .keys()
          .map((key) => ({ label: key, value: key }))
          .value();

        setOptions(allFields);
        setLoading(false);
      })
    );
  }, []);

  if (loading) return <Loading title={"Loading data fields"} />;

  return (
    <Select
      mode="multiple"
      allowClear
      style={{
        width: "100%",
      }}
      placeholder="Please select"
      defaultValue={["id"]}
      onChange={(values) => setFields && setFields(values)}
      options={options}
    />
  );
}
