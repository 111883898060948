import * as React from "react";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import DeveloperAPIKeys from "./DeveloperAPIKeys";

export default function DeveloperFlightsAPIKeys({ contract }) {
  return (
    <>
      <div>
        Our <strong>Flights API</strong> enables you to fetch flight data using
        a simple REST endpoint. You can also create Webhooks or use Zapier to
        create automations. For example, you can use{" "}
        <strong>Flights API</strong> to integrate CTOT data directly into your
        EFB software.
        <br />
        <br />
        {contract.flightApi && (
          <>
            <DeveloperAPIKeys
              contract={contract}
              scope={["flightApi"]}
              titleCreateNewKey="Create a new Flight API key"
            />
          </>
        )}
      </div>
      <br />
    </>
  );
}
