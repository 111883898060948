import * as React from "react";

import { Button, Input, Table } from "antd";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { contractUpdateUsersGQL } from "../../graphql/mutations";

export default function RemoveUserButton({ contract, user }) {
  const [mutateRemoveUser, { loading }] = useMutation(contractUpdateUsersGQL);

  const handleDeleteUser = () => {
    if (loading) return;
    
    if (window.confirm(`Delete ${user.email}?`)) {
      console.log(user);

      mutateRemoveUser({
        variables: {
          users: [user],
          contractId: contract._id,
          mode: "remove",
        },
        refetchQueries: ["user", "contractUserSearch"],
      });
    }
  };

  return (
    <Button
      icon={<FontAwesomeIcon icon={faTrash} />}
      type="ghost"
      onClick={handleDeleteUser}
      disabled={loading}
      loading={loading}
    />
  );
}
