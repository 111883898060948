import * as React from "react";

import _ from "lodash";
import { Button, Steps } from "antd";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import { useMutation } from "@apollo/client";
import { contractActivateGQL } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import SignupStep3 from "./SignupStep3";

export default function Signup({ contract }) {
  const navigate = useNavigate();

  const [current, setCurrent] = React.useState(0);
  const [price, setPrice] = React.useState();
  const [disabled, setDisabled] = React.useState(false);

  // Save the requiresStripe value in a memo as it gets set to false when payment details are saved
  // Prevents hidding Payment tab just after payment details are saved
  const displayPayment = React.useMemo(() => contract?.requiresStripe, []);
  const displayContract = React.useMemo(() => contract?.requiresContract, []);

  let steps = [
    {
      title: "Welcome",
      content: <SignupStep1 contract={contract} />,
    },
    {
      title: "Terms and condition",
      content: (
        <SignupStep2
          contract={contract}
          price={price}
          setPrice={setPrice}
          setDisabled={setDisabled}
        />
      ),
      hidden: !displayContract,
    },
    {
      title: "Payment details",
      content: (
        <SignupStep3
          contract={contract}
          disabled={disabled}
          setDisabled={setDisabled}
          setCurrent={setCurrent}
          price={price}
        />
      ),
      hidden: !displayPayment,
    },
    {
      title: "Activation",
      content: (
        <div>
          You have finished the activation process.
          <br />
          <br />
          You can use this Account Console to manage your account. For example,
          go to <strong>Users</strong> to update your Users list, i.e. the list
          of people having access to CTOT.
        </div>
      ),
      status: "finish",
    },
  ];

  steps = _.filter(steps, (step) => !step.hidden);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    status: item.status,
  }));

  const [mutateActivate, { loading }] = useMutation(contractActivateGQL);

  const next = () => {
    if (current === steps.length - 2) activate();
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const activate = () => {
    mutateActivate({
      variables: { contractId: contract._id },
      refetchQueries: ["user"],
    });
  };

  const done = () => {
    navigate("../users");
  };

  let nextTitle = "Next";
  if (current === 1)
    nextTitle = "Accept Terms and conditions, Privacy Policy and pricing";

  return (
    <div>
      <Steps
        current={current}
        items={items}
        size="small"
        style={{ width: "80%", margin: "auto", marginBottom: 30 }}
      />
      <br />
      <br />
      {steps[current].content}

      <br />
      <br />
      <br />
      <br />
      <div>
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            loading={loading}
            disabled={loading || disabled}
          >
            {nextTitle || "Next"}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={done}
            loading={loading}
            disabled={loading}
          >
            Go to Users
          </Button>
        )}
        {current > 0 && current < steps.length - 1 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </div>
  );
}
