import * as React from "react";

import {
  Typography,
  Tabs,
  Card,
  Descriptions,
  Badge,
  Button,
  Table,
  Divider,
} from "antd";

import _ from "lodash";

export default function DeveloperUsersAPI({ contract }) {
  return (
    <>
      <div>
        CTOT now supports{" "}
        <strong>SCIM</strong> (System for Cross-domain Identity Management) to
        streamline user provisioning and deprovisioning for your organization.
        <Divider />
        <Typography.Title level={5}>What is SCIM?</Typography.Title>
        SCIM is an open standard that simplifies the process of managing user
        identities across various systems. By implementing SCIM, CTOT enables
        organizations to automate user provisioning, ensuring efficient and
        secure onboarding, role management, and account updates.
        <Divider />
        <Typography.Title level={5}>
          How SCIM Benefits CTOT Users
        </Typography.Title>
        Automated User Provisioning: Add or remove users automatically as part
        of your organization’s identity management workflows. Secure and
        Compliant: Ensure that user information is updated securely and in
        real-time. Seamless Integration: SCIM allows CTOT to connect
        effortlessly with Identity Providers (IdPs) like Okta, Azure AD, and
        others, making it easy to manage user access.
        <Divider />
        <Typography.Title level={5}>Get Started</Typography.Title> Contact us to
        get your SCIM credential. For any questions or support, please reach out
        to us at support@ctot.app.
      </div>
      <br />
    </>
  );
}
