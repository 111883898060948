import * as React from "react";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function DeveloperZapier({ contract }) {
  const flightApiEnabled = _.get(contract, "flightApi");

  return (
    <div>
      Zapier is a plug and play service that requires{" "}
      <strong>zero coding</strong>. You can trigger actions based on
      notifications received.
      <br />
      For example, send an SMS or email when a CTOT changes or track flight data
      in an excel spreadsheet.
      <br />
      <br />
      <Link
        to="https://zapier.com/developer/public-invite/184988/b81c56b5e01f2ea47e9f4b5c9a87a0a9/"
        target="_blank"
      >
        <FontAwesomeIcon icon={faExternalLink} style={{ cursor: "pointer" }} />{" "}
        Start building your integration with Zapier
      </Link>
      <br /> <br /> <br />
    </div>
  );
}
