import * as React from "react";

import { Button, Table } from "antd";

import moment from "moment";

import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  contractCreateApiKeyGQL,
  contractDeleteApiKeyGQL,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import HiddenText from "../elements/HiddenText.js";

export default function DeveloperAPIKeys({
  contract,
  scope,
  titleCreateNewKey,
}) {
  return (
    <>
      <Api
        contract={contract}
        scope={scope}
        titleCreateNewKey={titleCreateNewKey}
      />
    </>
  );
}

const columns = [
  {
    title: "Created at",
    dataIndex: ["createdAt"],
    render: (ele) => ele && moment.utc(ele).format("YYYY-MM-DD HH:mm"),
    key: "createdAt",
    order: 1,
  },
  {
    title: "Key",
    dataIndex: ["key"],
    //render: (ele) => <DisplayKey apiKey={ele} />,
    render: (ele) => <HiddenText value={ele} />,
    key: "key",
    order: 2,
  },
  {
    title: "Scope",
    dataIndex: ["scope"],
    render: (ele) => _.toLower(ele?.join(" ")),
    key: "scope",
    order: 3,
  },
  {
    title: "Delete",
    render: (ele) => <OneKeyActions apiKey={ele} />,
  },
];

function Api({ contract, scope, titleCreateNewKey }) {
  let [requestApiKey, { loading }] = useMutation(contractCreateApiKeyGQL, {
    variables: { contractId: contract._id, scope },
    refetchQueries: ["user"],
  });

  const apiKeys = _.get(contract, "keys", []);

  return (
    <div className="">
      You can manage your Api Keys here. This can be usefull if you wish to
      automate your user managementvia the User Api or access our Fligth Api.
      <br /> <br />
      <Button
        type="primary"
        className="ant-btn"
        onClick={requestApiKey}
        loading={loading}
      >
        {titleCreateNewKey || "Create a new Api Key"}
      </Button>
      <br />
      <br />
      <Table dataSource={apiKeys} columns={columns} pagination={false} />
    </div>
  );
}

function OneKeyActions(props) {
  let params = useParams();

  const apiKey = props.apiKey;
  const contractId = params.contractId;

  let [deleteApiKey, { loading }] = useMutation(contractDeleteApiKeyGQL, {
    variables: { contractId, key: apiKey.key },
    refetchQueries: ["user"],
  });
  return (
    <div className="">
      <Button
        icon={
          <FontAwesomeIcon
            icon={faTrash}
            onClick={deleteApiKey}
            style={{ cursor: "pointer", fontSize: 18 }}
          />
        }
        loading={loading}
        type="ghost"
      />
    </div>
  );
}

function DisplayKey(props) {
  const apiKey = props.apiKey;
  const hiddenApiKey =
    (apiKey &&
      "*".repeat(Math.max(apiKey.length, 10) - 10) +
        apiKey.substring(apiKey.length - 10, apiKey.length)) ||
    "You don't have an Api Key. Please create one.";

  const [color, setColor] = React.useState("#fff");
  const [hidden, setHidden] = React.useState(true);

  const onClickDisplay = () => {
    setHidden(!hidden);
  };

  const onClickColor = () => {
    setColor("#5F5");
    setTimeout(() => setColor("white"), 500);
  };

  return (
    <div className="">
      <div style={{ fontFamily: "monospace", overflowWrap: "anywhere" }}>
        {hidden ? hiddenApiKey : apiKey}{" "}
      </div>
      <FontAwesomeIcon
        icon={hidden ? faEye : faEyeSlash}
        onClick={() => {
          onClickDisplay();
        }}
        style={{ cursor: "pointer", fontSize: 18 }}
      />
      &nbsp;
      <FontAwesomeIcon
        icon={faCopy}
        onClick={() => {
          onClickColor();
          navigator.clipboard.writeText(apiKey);
        }}
        style={{ color, cursor: "pointer", fontSize: 18 }}
      />
    </div>
  );
}
