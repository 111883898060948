import * as React from "react";
import _ from "lodash";
import OpenApiViewer3 from "./OpenApiViewer3.js";
import { Divider } from "antd";

export default function DeveloperFlightsAPIRest({ contract }) {
  return (
    <>
      <div>
        <div>
          <strong>Introduction</strong>
          <br />
          The Flight API REST service provides powerful tools to access
          comprehensive flight data. With the REST API, you can:
          <ul>
            <li>
              <strong>Search Flights</strong>: Perform custom searches to find
              flights based on criteria like operator, departure airport, or
              aircraft registration.
            </li>
            <li>
              <strong>Fetch Flight Data</strong>: Retrieve detailed data on
              flights, including CTOT, TSAT, and operational details.
            </li>
          </ul>
          For those who need <strong>real-time</strong> instant updates, the
          REST API works seamlessly in combination with our{" "}
          <strong>Webhooks</strong>. While the REST API allows you to fetch data
          on demand, the <strong>Webhooks</strong> notify your system
          automatically whenever flight data changes, ensuring your application
          stays current at all times.
          <br />
          <strong>Webhooks</strong> will provide the same information as this
          REST api.
        </div>
        <Divider />
        <strong>Flight API REST documentation</strong>

        {contract.flightApi && (
          <>
            <OpenApiViewer3 />
          </>
        )}
      </div>
      <br />
    </>
  );
}
