import * as React from "react";

import { Typography, Tabs, Button, Table, Tag } from "antd";

import moment from "moment";

import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  contractCreateApiKeyGQL,
  contractDeleteApiKeyGQL,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faTrash,
  faWarning,
  faZap,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import DeveloperWebhooks from "./DeveloperWebhooks";
import DeveloperZapier from "./DeveloperZapier";
import DeveloperAPIKeys from "./DeveloperAPIKeys";
import DeveloperFlightsAPIKeys from "./DeveloperFlightsAPIKeys";
import { RiWebhookFill } from "react-icons/ri";
import { SiZapier } from "react-icons/si";
import { FaKey } from "react-icons/fa6";
import { MdHttp } from "react-icons/md";
import DeveloperFlightsAPIRest from "./DeveloperFlightsAPIRest.js";

export default function DeveloperFlightAPI({ contract }) {
  const items = [
    {
      key: "3",
      label: "API Keys",
      icon: (
        <FaKey
          style={{
            verticalAlign: "middle",
            fontSize: 16,
          }}
        />
      ),
      children: <DeveloperFlightsAPIKeys contract={contract} />,
    },
    {
      key: "2",
      label: "REST API",
      icon: (
        <MdHttp
          style={{
            verticalAlign: "middle",
            fontSize: 18,
          }}
        />
      ),
      children: <DeveloperFlightsAPIRest contract={contract} />,
    },
    {
      key: "4",
      label: "Webhooks",
      icon: (
        <RiWebhookFill
          style={{
            verticalAlign: "middle",
            fontSize: 18,
          }}
        />
      ),
      children: <DeveloperWebhooks contract={contract} />,
    },
    {
      key: "5",
      label: "Zapier",
      icon: (
        <SiZapier
          style={{
            verticalAlign: "middle",
            fontSize: 16,
          }}
        />
      ),
      children: <DeveloperZapier contract={contract} />,
    },
  ];

  return (
    <>
      <div>
        Our <strong>Flights API</strong> enables you to fetch flight data using
        a simple REST endpoint. You can also create Webhooks or use Zapier to
        create automations. For example, you can use{" "}
        <strong>Flights API</strong> to integrate CTOT data directly into your
        EFB software.
        <br />
        <br />
        {!contract.flightApi && (
          <Tag color="orange" style={{ marginTop: 10, padding: 10 }}>
            <FontAwesomeIcon icon={faWarning} /> Your access to{" "}
            <strong>Flights API</strong> is currently disabled. Contact us at
            contact@ctot.app to activate your <strong>Flights API</strong>.
          </Tag>
        )}
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}
