import * as React from "react";

import _ from "lodash";
import constants from "../../api/constants";
import Stripe from "../stripe/Stripe";

export default function SignupStep3({
  contract,
  price,
  setPrice,
  setDisabled,
}) {
  React.useEffect(() => {
    setDisabled(true);
  }, []);

  return (
    <div>
      <Stripe
        contract={contract}
        price={price}
        onComplete={() => setDisabled(false)}
      />
    </div>
  );
}
