import * as React from "react";

import { Input, Table } from "antd";

import _ from "lodash";
import RemoveUserButton from "./RemoveUserButton";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { contractUserSearchGQL } from "../../graphql/queries";
import validator from "validator";

export default function ActiveUsers({ contract }) {
  let users = _.get(contract, "users", []);

  const [search, setSearch] = React.useState("");

  const { data, loading } = useQuery(contractUserSearchGQL, {
    variables: { email: search, contractId: contract._id },
  });

  if (search.length > 0) users = data?.contractUserSearch;

  if (
    search.length > 0 &&
    _.size(data?.contractUserSearch) === 1 &&
    validator.isEmail(search) &&
    _.get(search.split("@"), "[1]") ===
      _.get(_.get(data, "contractUserSearch[0].email").split("@"), "[1]")
  ) {
    users = data?.contractUserSearch;
    users = _.map(users, (ele) => ({ ...ele, email: search }));
  }

  users = _.filter(users, (user) => user.type !== "device");

  users = _.orderBy(users, "email");

  const columns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Added on",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (ele) => moment.utc(ele).format("YYYY-MM-DD HH:mm z"),
    },
    /*  {
      title: "Added by",
      key: "type",
      dataIndex: "type",
      render: (ele) => (ele === "scim" ? "Sync by SCIM" : "Manually added"),
    }, */
    {
      title: "Actions",
      key: "actions",
      render: (ele) =>
        (ele.type !== "scim" && (
          <RemoveUserButton user={ele} contract={contract} />
        )) ||
        "Managed by SCIM",
    },
  ];

  return (
    <>
      <Input.Search
        placeholder="Search for active users"
        onChange={(ev) => setSearch(ev.currentTarget.value)}
      />
      <br />
      <small>
        Search for the full email to show the user's email without obfuscation.
        No cleartext emails are sent from our servers for security reasons.
      </small>
      <br />
      <br />
      <Table
        dataSource={users}
        columns={columns}
        pagination={true}
        rowKey={"_id"}
        size="small"
        loading={loading}
      />
    </>
  );
}
