const constantsDev = {
  API_URL: "http://localhost:4000",
  //API_URL: "http://192.168.1.11:4000"
  //API_URL: "https://api.ctot.app",
  STRIPE_PUBLIC_KEY:
    "pk_test_51IFiUGD0l1OAQaoah8l5ylk2KMGIcKAM1KNXLJffX4z3Ej6Ft9TUmw8rziRrVB3hX5pISFDaIZXAYbxMguNPp0Hq00ulxCEZrt",
};

const constantsProd = {
  // API
  API_URL: "https://data.ctot.app",
  STRIPE_PUBLIC_KEY:
    "pk_live_51IFiUGD0l1OAQaoa2A4WOUcmVUDmVilvEduZMbap9UPIKEKPpYnGSYKV3I6l382dMFhGW2wUbLGZVFYjWdrVXM4x00D0HXWkON",
};

if (process.env.NODE_ENV !== "production") {
  //uri = "http://localhost:4000/graphql";
  //uri = "http://192.168.1.11:4000/graphql";
  console.warn("⚠️  Loading DEV constants", constantsDev);
}

const constants =
  process.env.NODE_ENV !== "production" ? constantsDev : constantsProd;

export default constants;
