import { Tag, Tabs, Tooltip, Collapse } from "antd";
import _ from "lodash";
import * as React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  dracula as prismStyle,
  oneLight as lightTheme,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import constants from "../../api/constants.js";

import "../../styles/openapi-viewer.css";
import { faNodeJs, faPhp, faPython } from "@fortawesome/free-brands-svg-icons";
import { faCode, faL, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Language mapping for syntax highlighting
const languageMap = {
  "Node + Request": "javascript",
  "Shell + Curl": "bash",
  "Shell + Httpie": "bash",
  "Python + Python3": "python",
  "Php + Curl": "php",
  "Php + Http1": "php",
  "Php + Http2": "php",
};

// Language icons mapping
const languageIcons = {
  "Node + Request": faNodeJs,
  "Shell + Curl": faTerminal,
  "Shell + Httpie": faTerminal,
  "Python + Python3": faPython,
  "Php + Curl": faPhp,
  "Php + Http1": faPhp,
  "Php + Http2": faPhp,
};

// Helper function to get a friendly type name
const getTypeName = (schema, openapi) => {
  if (!schema) return "any";

  // Handle $ref by resolving it first
  if (schema.$ref) {
    const resolved = getComponentFromRef(openapi, schema.$ref);
    if (resolved?.schema) {
      return getTypeName(resolved.schema, openapi);
    }
  }

  if (schema.enum) return "enum";
  if (schema.oneOf) return "oneOf";
  if (schema.anyOf) return "anyOf";
  if (schema.allOf) return "allOf";
  return schema.type || "object";
};

// Status code badge component
const StatusCodeBadge = ({ code }) => {
  const getStatusColor = (code) => {
    const firstDigit = code.charAt(0);
    switch (firstDigit) {
      case "2":
        return "green";
      case "3":
        return "blue";
      case "4":
        return "orange";
      case "5":
        return "red";
      default:
        return "default";
    }
  };

  return (
    <Tag
      color={getStatusColor(code)}
      style={{ minWidth: "60px", textAlign: "center" }}
    >
      {code}
    </Tag>
  );
};

// Improved reference handling
const getComponentFromRef = (openapi, $ref) => {
  try {
    if (!$ref?.startsWith("#/")) return null;
    const path = $ref.substring(2).split("/");
    let current = openapi;
    for (const segment of path) {
      current = current[segment];
    }
    return { name: path[path.length - 1], schema: current };
  } catch (e) {
    console.error("Error resolving reference:", e);
    return null;
  }
};

const SchemaView = ({ schema, openapi, examples, level = 0 }) => {
  if (!schema) return null;

  // Handle references
  if (schema.$ref) {
    const resolved = getComponentFromRef(openapi, schema.$ref);
    if (resolved) {
      return (
        <SchemaView
          schema={resolved.schema}
          openapi={openapi}
          examples={examples}
          level={level}
        />
      );
    }
    return <div>Unable to resolve reference: {schema.$ref}</div>;
  }

  // Generate example value for arrays and objects
  const generateExampleValue = (schema) => {
    // Handle references first
    if (schema.$ref) {
      const resolved = getComponentFromRef(openapi, schema.$ref);
      if (resolved?.schema) {
        // Check for example in the resolved schema
        if (resolved.schema.example !== undefined) {
          return resolved.schema.example;
        }
        return generateExampleValue(resolved.schema);
      }
    }

    if (schema.example !== undefined) return schema.example;

    if (schema.type === "array" && schema.items) {
      return [generateExampleValue(schema.items)];
    }

    if (schema.type === "object" || schema.properties) {
      const example = {};
      Object.entries(schema.properties || {}).forEach(([key, prop]) => {
        example[key] = generateExampleValue(prop);
      });
      return example;
    }

    // Default examples for primitive types
    switch (schema.type) {
      case "string":
        return schema.enum ? schema.enum[0] : "example";
      case "number":
        return 123.45;
      case "integer":
        return 123;
      case "boolean":
        return true;
      default:
        return null;
    }
  };

  return (
    <div className="ctot-code-response-schema" style={{ marginLeft: level * 5 }}>
      <div className="ctot-code-response-type">
        <strong>Type: </strong>
        <Tag color="purple">{getTypeName(schema, openapi)}</Tag>
        {schema.format && <Tag color="cyan">{schema.format}</Tag>}
        {schema.nullable && <Tag color="orange">nullable</Tag>}
      </div>

      {schema.description && (
        <div className="ctot-code-response-description">
          {schema.description}
        </div>
      )}

      {/* Handle array items */}
      {schema.type === "array" && schema.items && (
        <div className="ctot-code-response-array">
          <h4>Array Items:</h4>
          <div className="ctot-code-indent">
            <SchemaView
              schema={schema.items}
              openapi={openapi}
              level={level + 1}
            />
          </div>
        </div>
      )}

      {/* Handle object properties */}
      {schema.properties && (
        <div className="ctot-code-response-properties">
          <h4>Properties:</h4>
          <Collapse size="small">
            {Object.entries(schema.properties).map(([key, prop]) => (
              <Collapse.Panel
                key={key}
                header={
                  <div className="ctot-code-property-header">
                    <div>
                      <code>{key}</code>
                      {schema.required?.includes(key) && (
                        <span className="required-star">*</span>
                      )}
                    </div>
                    <Tag color="purple">{getTypeName(prop, openapi)}</Tag>
                    {prop.description && (
                      <span style={{ color: "#666", fontSize: "0.9em" }}>
                        {prop.description}
                      </span>
                    )}
                  </div>
                }
              >
                <SchemaView schema={prop} openapi={openapi} level={level + 1} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      )}

      {/* Show enum values */}
      {schema.enum && (
        <div className="ctot-code-response-enum">
          <h4>Possible Values:</h4>
          <div className="ctot-code-enum-values">
            {schema.enum.map((value, index) => (
              <Tag key={index} color="blue">
                {JSON.stringify(value)}
              </Tag>
            ))}
          </div>
        </div>
      )}

      {/* Show examples */}
      {(examples || schema.example || schema.properties || schema.items) && (
        <div className="ctot-code-response-examples">
          <Collapse size="small" style={{ marginLeft: 0, borderRadius: 5 }}>
            <Collapse.Panel
              header="Example"
              style={{ padding: 0 }}
              styles={{ body: { padding: 0 } }}
            >
              <SyntaxHighlighter
                language="json"
                style={lightTheme}
                className="ctot-code-block"
              >
                {JSON.stringify(
                  examples?.value ||
                    schema.example ||
                    generateExampleValue(schema),
                  null,
                  2
                )}
              </SyntaxHighlighter>
            </Collapse.Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

const ResponsesView = ({ responses, openapi }) => (
  <div className="ctot-code-responses-container">
    <Collapse defaultActiveKey={["200"]}>
      {responses &&
        Object.entries(responses).map(([code, response]) => {
          // Generate better example for 200 response of /flights endpoint
          let enhancedResponse = response;
          if (
            code === "200" &&
            response.content?.["application/json"]?.schema?.$ref?.includes(
              "flightsRes"
            )
          ) {
            enhancedResponse = {
              ...response,
              content: {
                "application/json": {
                  ...response.content["application/json"],
                  examples: {
                    "Success Response": {
                      value: {
                        data: [
                          {
                            id: "EGSSLGZA202408150455ABC53W",
                            callsign: "ABC123",
                            depIcao: "EGSS",
                            arrIcao: "LGZA",
                            registration: "GABC1",
                            fplId: "AT02981163",
                            operator: "ABC",
                            operatorReal: "ABC",
                            taxiDuration: "PT15M",
                            depAirportType: "CDM",
                            eobt: "2024-08-15T04:55:00Z",
                            tobt: "2024-08-15T04:55:00Z",
                            tsat: "2024-08-15T05:10:00Z",
                            ctot: "2024-08-15T05:25:00Z",
                            etot: "2024-08-15T05:10:00Z",
                            atot: null,
                            aobt: null,
                            eta: "2024-08-15T08:25:00Z",
                            altIcaos: ["LGKR", "LGPZ"],
                            depTerminal: "Terminal1",
                            depStand: "12",
                            arrTerminal: null,
                            arrStand: null,
                            regulations: [
                              {
                                id: "KK123",
                                locationId: "EDUUKK",
                                type: "AIRSPACE",
                              },
                            ],
                            fplStatus: "NOT_SUSPENDED",
                            flightStatus: "FILED_SLOT_ALLOCATED",
                            readyForImprovement: true,
                            readyToDepart: false,
                          },
                        ],
                        type: "flights",
                        reqId: "42f471d8-afee-4500-9cfa-ddb521dcb2ce",
                      },
                    },
                  },
                },
              },
            };
          }

          return (
            <Collapse.Panel
              key={code}
              header={
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <StatusCodeBadge code={code} />
                  <span>{enhancedResponse.description}</span>
                </div>
              }
            >
              {enhancedResponse.content &&
                Object.entries(enhancedResponse.content).map(
                  ([mediaType, content]) => (
                    <div key={mediaType} className="ctot-code-response-content">
                      <Tag color="blue">{mediaType}</Tag>
                      <SchemaView
                        schema={content.schema}
                        openapi={openapi}
                        examples={content.examples?.["Success Response"]}
                      />
                    </div>
                  )
                )}
            </Collapse.Panel>
          );
        })}
    </Collapse>
  </div>
);

const CodeExamplesView = ({ codeSamples }) => {
  const [selectedLang, setSelectedLang] = React.useState(
    codeSamples?.[0]?.lang
  );

  if (!codeSamples?.length) {
    return <div>No code examples available</div>;
  }

  const currentSample = codeSamples.find(
    (sample) => sample.lang === selectedLang
  );

  return (
    <div className="ctot-code-examples-container">
      <div className="ctot-code-lang-buttons-container">
        <div className="ctot-code-lang-buttons">
          {codeSamples.map((sample) => {
            const isActive = sample.lang === selectedLang;
            const iconName = languageIcons[sample.lang] || "code";
            const icon = languageIcons[sample.lang] || faCode;
            return (
              <button
                key={sample.lang}
                onClick={() => setSelectedLang(sample.lang)}
                className={`ctot-code-lang-button ${
                  isActive ? "active" : "inactive"
                }`}
              >
                <FontAwesomeIcon icon={icon} height={20} />
                <div>{sample.lang}</div>
              </button>
            );
          })}
        </div>
      </div>

      <div className="ctot-code-block-container">
        <SyntaxHighlighter
          language={languageMap[currentSample?.lang] || "text"}
          style={prismStyle}
          className="ctot-code-block"
        >
          {currentSample?.source || ""}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const InfoSection = ({ openapi }) => (
  <div className="ctot-code-section">
    <h1>{openapi.info?.title}</h1>
    <p>{openapi.info?.description}</p>
    {openapi.info?.version && (
      <Tag color="blue">Version: {openapi.info.version}</Tag>
    )}
  </div>
);

const ServersSection = ({ servers }) => (
  <div>
    <h2>Servers</h2>
    {servers?.map((server, index) => (
      <div key={index} className="ctot-code-server-viewer-item">
        <SyntaxHighlighter
          language="text"
          style={prismStyle}
          className="ctot-code-block-medium"
        >
          {server.url}
        </SyntaxHighlighter>
        <div>{server.description}</div>
        {server.variables && (
          <div className="ctot-code-indent">
            <h4>Variables:</h4>
            {Object.entries(server.variables).map(([key, value]) => (
              <div key={key}>
                <code>{key}</code>: {value.description}
                {value.default && <span> (Default: {value.default})</span>}
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
);

const SecuritySection = ({ security }) => (
  <div>
    <h2>Authentication</h2>
    {security &&
      Object.entries(security).map(([key, scheme]) => (
        <div key={key} className="ctot-code-section">
          <h3>
            <Tag color="green">{scheme.type}</Tag> {key}
          </h3>
          <div>
            <strong>In:</strong> {scheme.in}
            {scheme.name && (
              <>
                {" "}
                | <strong>Name:</strong> {scheme.name}
              </>
            )}
          </div>
          <p>{scheme.description}</p>
        </div>
      ))}
  </div>
);

const EndpointsSection = ({ paths, openapi }) => (
  <div>
    <h2>Endpoints</h2>
    {paths &&
      Object.entries(paths).map(([path, operations]) => (
        <div key={path} className="ctot-code-endpoint-container">
          {Object.entries(operations).map(([method, operation]) => (
            <OperationView
              key={`${path}-${method}`}
              path={path}
              method={method}
              operation={operation}
              openapi={openapi}
            />
          ))}
        </div>
      ))}
  </div>
);

const OperationView = ({ path, method, operation, openapi }) => {
  const [activeTab, setActiveTab] = React.useState("1");

  const methodColors = {
    get: "green",
    post: "blue",
    put: "orange",
    delete: "red",
    patch: "purple",
  };

  return (
    <div className="ctot-code-layout-container">
      <div className="ctot-code-layout-content-left">
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Tag color={methodColors[method]} style={{ fontSize: "16px" }}>
            {method.toUpperCase()}
          </Tag>
          <h2 style={{ margin: 0 }}>{path}</h2>
          {operation.deprecated && <Tag color="red">Deprecated</Tag>}
        </div>

        <p>{operation.summary}</p>
        <p>{operation.description}</p>

        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <Tabs.TabPane tab="Parameters" key="1">
            <ParametersView
              parameters={operation.parameters}
              openapi={openapi}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Request Body" key="2">
            <RequestBodyView
              requestBody={operation.requestBody}
              openapi={openapi}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Responses" key="3">
            <ResponsesView responses={operation.responses} openapi={openapi} />
          </Tabs.TabPane>
          {operation.security && (
            <Tabs.TabPane tab="Security" key="4">
              <SecurityRequirementsView
                security={operation.security}
                schemes={openapi.components?.securitySchemes}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>

      <div className="ctot-code-layout-content-right">
        <h3>Code Examples</h3>
        <CodeExamplesView codeSamples={operation["x-codeSamples"]} />
      </div>
    </div>
  );
};

const ParametersView = ({ parameters, openapi }) => {
  if (!parameters?.length) return <div>No parameters required</div>;

  const paramsByLocation = _.groupBy(parameters, "in");

  return Object.entries(paramsByLocation).map(([location, params]) => (
    <div key={location}>
      <h3 className="ctot-code-section-title">
        {_.startCase(location)} Parameters
      </h3>
      {params.map((param, index) => (
        <div key={index} className="ctot-code-section">
          <div>
            <code className="ctot-code-param">{param.name}</code>
            <span className="ctot-code-param-addon">
              {param.required ? "required" : "optional"}
              {param.schema && ` • ${getTypeName(param.schema, openapi)}`}
              {param.deprecated && " • deprecated"}
            </span>
          </div>
          <div>{param.description}</div>
          {param.schema && (
            <SchemaView schema={param.schema} openapi={openapi} />
          )}
        </div>
      ))}
    </div>
  ));
};

const RequestBodyView = ({ requestBody, openapi }) => {
  if (!requestBody) return <div>No request body required</div>;

  return (
    <div className="ctot-code-section">
      {requestBody.description && <p>{requestBody.description}</p>}
      {requestBody.content &&
        Object.entries(requestBody.content).map(([mediaType, content]) => (
          <div key={mediaType}>
            <h4>{mediaType}</h4>
            <SchemaView
              schema={content.schema}
              openapi={openapi}
              examples={content.examples}
            />
          </div>
        ))}
    </div>
  );
};

const SecurityRequirementsView = ({ security, schemes }) => (
  <div className="ctot-code-section">
    {security.map((requirement, index) => (
      <div key={index}>
        <h4>Security Requirement {index + 1}</h4>
        {Object.entries(requirement).map(([schemeName, scopes]) => {
          const scheme = schemes?.[schemeName];
          return (
            <div key={schemeName}>
              <strong>{schemeName}</strong> ({scheme?.type})
              {scopes?.length > 0 && (
                <div className="ctot-code-indent">
                  Required scopes:
                  {scopes.map((scope) => (
                    <Tag key={scope} color="blue">
                      {scope}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

export default function OpenApiViewer3() {
  const [openapi, setOpenapi] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const getOpenapi = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        constants.API_URL + "/rest/v1/flights/docs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();
      setOpenapi(res);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getOpenapi();
  }, []);

  if (loading) return <div>Loading API documentation...</div>;
  if (error) return <div>Error loading API documentation: {error}</div>;

  return (
    <div className="ctot-code-main-container">
      <InfoSection openapi={openapi} />
      <ServersSection servers={openapi.servers} />
      <SecuritySection security={openapi.components?.securitySchemes} />
      <EndpointsSection paths={openapi.paths} openapi={openapi} />
    </div>
  );
}
