import * as React from "react";

import { Typography, Tabs, Card, Tag } from "antd";

import _ from "lodash";
import ActiveDevices from "./ActiveDevices";
import AddDevices from "./AddDevices";

export default function Devices({ contract }) {
  const deviceNumber = _.chain(contract)
    .get("users", [])
    .filter((user) => user.type === "device")
    .size()
    .value();

  const userNumber = _.chain(contract)
    .get("users", [])
    .filter((user) => user.type !== "device")
    .size()
    .value();

  const itemsDevices = [
    {
      key: "d1",
      label: `Active devices (${deviceNumber})`,
      children: (
        <div className="box">
          <ActiveDevices contract={contract} />
        </div>
      ),
    },
    {
      key: "d2",
      label: "Add devices",
      children: (
        <div className="box">
          <AddDevices contract={contract} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div>
          This section contains the devices having access to CTOT app.
          <br />
          <br />
          Devices are{" "}
          <strong>similar to users but do not have access to an email</strong>.
          This can be for example an EFB belonging to an aircraft. Please note
          that one device counts as one user for billing purposes and one device
          can only be bound to one physical device.
        </div>

        <Tag color="green" style={{ marginTop: 20, padding: 10 }}>
          <div>How to login a device?</div>
          <div style={{ color: "black" }}>
            Use the full email provided in the following table with the
            corresponding login code. <br />
            Note: the login code always remains the same for a given device.
          </div>
        </Tag>
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        //type="card"
        items={itemsDevices}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}
