import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "antd";

const green = "#1DC34C";

export default function HiddenText({ value, iconColor = "#000" }) {
  const [color, setColor] = React.useState(iconColor);
  const [hidden, setHidden] = React.useState(true);
  const [blinking, setBlinking] = React.useState(false);

  const onClickDisplay = () => {
    setHidden(!hidden);
  };

  const onClickColor = () => {
    setColor(green);
    setBlinking(true);
    setTimeout(() => {
      setColor(iconColor);
      setBlinking(false);
    }, 400);
  };

  const hiddenValue =
    (value &&
      "*".repeat(Math.max(value.length, 10) - 10) +
        value.substring(value.length - 10, value.length)) ||
    "You don't have an Api Key. Please create one.";

  return (
    <div style={{ minWidth: "10ch" }}>
      <span
        style={{
          fontFamily: "monospace",
          overflowWrap: "anywhere",
          color: blinking ? green : "",
          cursor: "pointer",
        }}
        className="hover-opacity"
        onClick={() => {
          onClickColor();
          navigator.clipboard.writeText(value);
        }}
      >
        <Tooltip placement="topLeft" title={"Copy value"}>
          {hidden ? hiddenValue : value}
        </Tooltip>
      </span>
      <FontAwesomeIcon
        icon={hidden ? faEye : faEyeSlash}
        onClick={() => {
          onClickDisplay();
        }}
        style={{
          cursor: "pointer",
          //color: iconColor,
          width: 15,
          textAlign: "center",
          marginRight: 10,
          marginLeft: 10,
        }}
      />
      <Tooltip placement="topLeft" title={"Copy value"}>
        <FontAwesomeIcon
          icon={faCopy}
          onClick={() => {
            onClickColor();
            navigator.clipboard.writeText(value);
          }}
          style={{
            color,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </div>
  );
}
