import * as React from "react";

import { Button, Input, Table } from "antd";

import _ from "lodash";
import RemoveUserButton from "../users/RemoveUserButton";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { contractUserSearchGQL } from "../../graphql/queries";
import validator from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

export default function ActiveDevices({ contract }) {
  let users = _.get(contract, "users", []);
  users = _.filter(users, (user) => user.type === "device");

  const [search, setSearch] = React.useState("");

  const { data, loading } = useQuery(contractUserSearchGQL, {
    variables: { email: search, contractId: contract._id },
  });

  if (search.length > 0) users = data?.contractUserSearch;

  if (
    search.length > 0 &&
    _.size(data?.contractUserSearch) === 1 &&
    validator.isEmail(search) &&
    _.get(search.split("@"), "[1]") ===
      _.get(_.get(data, "contractUserSearch[0].email").split("@"), "[1]")
  ) {
    users = data?.contractUserSearch;
    users = _.map(users, (ele) => ({ ...ele, email: search }));
  }

  users = _.orderBy(users, "email");

  const exportCSV = () => {
    const usersForExport = _.map(users, (user) => ({
      email: user.email,
      code: user.code,
      createdAt: user.createdAt
    }));
    const csvData = new Blob([convertToCSV(usersForExport)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `ctot_devices.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      title: "Device name",
      key: "device",
      dataIndex: "email",
      render: (ele) => _.chain(ele).split("@").first().value(),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Login code",
      key: "loginCode",
      dataIndex: "code",
    },
    {
      title: "Added on",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (ele) => moment.utc(ele).format("YYYY-MM-DD HH:mm z"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (ele) => <RemoveUserButton user={ele} contract={contract} />,
    },
  ];

  return (
    <>
      <div style={{ width: "100%", display: "flex", gap: 20 }}>
        <Input.Search
          placeholder="Search for active devices"
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />
        <Button
          onClick={exportCSV}
          icon={<FontAwesomeIcon icon={faFileExport} />}
        >
          Export Devices Codes in CSV
        </Button>
      </div>

      <br />

      <small>
        Search for the full email to show the user's email without obfuscation.
        No cleartext emails are sent from our servers for security reasons.
      </small>

      <br />
      <br />
      <Table
        dataSource={users}
        columns={columns}
        pagination={true}
        rowKey={"_id"}
        size="small"
        loading={loading}
      />
    </>
  );
}
