import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";

export default function Loading({ size, title }) {
  return (
    <Space>
      <Spin indicator={<LoadingOutlined spin size={size} />} />
      {title}
    </Space>
  );
}
