import * as React from "react";

import _ from "lodash";
import constants from "../../api/constants";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

export default function SignupStep2({
  contract,
  price,
  setPrice,
  setDisabled,
}) {
  const currency = _.get(price, "currency");

  const priceId = _.chain(contract).get("setup.stripePriceId").value();

  //const requiresStripe = contract?.requiresStripe;

  let url = constants.API_URL + "/stripe/price/";
  if (priceId) {
    url = url + priceId;
  }

  const getData = async () => {
    const res = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    if (res.status === 200) setPrice((await res.json())?.price);
  };

  let tiersUpgraded = _.chain(price)
    .get("tiers", [])
    .map((tier, i, tiers) => {
      let from = 1;
      if (i > 0) from = tiers[i - 1].up_to + 1;
      if (i === tiers.length - 1) tier.up_to = undefined;
      return { from, ...tier };
    })
    .value();

  if (!price?.tiers) {
    tiersUpgraded = [
      {
        from: "For each user",
        flat_amount: null,
        flat_amount_decimal: null,
        unit_amount: price?.unit_amount,
        unit_amount_decimal: price?.unit_amount_decimal,
        up_to: "unlimited",
      },
    ];
  }

  const columns = [
    {
      title: "User position",
      render: (ele) =>
        `${_.get(ele, "from", 0)} ${
          _.get(ele, "up_to") ? " to " + _.get(ele, "up_to") : "and more"
        }`,
      key: "userIndex",
    },
    {
      title: "Price per user/month",
      render: (ele) => _.get(ele, "unit_amount", 0) / 100 + " " + currency,
      key: "price",
    },
  ];

  React.useEffect(() => {
    setDisabled(false);
    getData();
  }, []);

  return (
    <div>
      Please take a moment to read our{" "}
      <Link to={"https://www.ctot.app/terms-conditions#top"} target="_blank">
        Terms and Conditions
      </Link>{" "}
      as well as our{" "}
      <Link to={"https://www.ctot.app/privacy-policy#top"} target="_blank">
        Privacy policy
      </Link>
      <div style={{ marginTop: 30 }}>
        Your pricing will be as per the following table. We use a graduated
        pricing, the first 50 users will cost you 1.79 euros/month/user and the
        51th user will cost you 1.59 euros/month etc.
        <br />
        <br />
        * Please note that a minimum bill is set for 20 users.
        <br />
        <br />
        <Table
          columns={columns}
          dataSource={tiersUpgraded}
          rowKey={(record) => record.from}
          pagination={false}
          //loading={!Boolean(price)}
          loading={!price && { indicator: <LoadingOutlined spin /> }}
        />
      </div>
    </div>
  );
}
